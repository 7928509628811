import React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet"
import GoogleImageSearch from "./google";
import PixabayImageSearch from "./pixabay";
import NounProject from "./noun-project";
import Tags from "./tags";

function ImageSearchPage(props) {
  return (
    <div>
      <Helmet title="Image Search" />
      <Routes>
        <Route path="/google/*" element={<GoogleImageSearch />} />
        <Route path="/pixabay" element={<PixabayImageSearch />} />
        <Route path="/noun-project" element={<NounProject />} />
        <Route path="/tags" element={<Tags />} />
      </Routes>
    </div>
  );
}

export default ImageSearchPage;
