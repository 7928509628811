import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import FlatButton from "../button/flat";
import CrossIcon from "../../../images/svg/cross.svg";
import ArrowIcon from "../../../images/svg/arrow.svg";
import SvgIcon from "../ui/svg-icon";
import Loader from "../ui/loader";
import "./index.scss";

export default function Modal (props) {
  const [promptedToCancel, setPromptedToCancel] = useState(false);
  const [bodyWidth] = useState(props.width ? props.width : null);
  const [visible] = useState(true);
  const nodeRef = useRef(null);

  const {
    title,
    body,
    bodyRenderer,
    onConfirm,
    onCancel,
    showX,
    showBackButton,
    onBackButtonClick,
    confirmText,
    cancelText,
    confirmButtonType,
    confirmButtonDisabled,
    confirmButtonLoading,
    cancelButtonType,
    cancelButtonDisabled,
    cancelButtonLoading,
    promptToCancel,
    promptToCancelText,
    promptToCancelType,
    modal,
    children,
    topOfScreenOnIpad,
    hideFooter,
    align,
    notransition,
    loadingMessage,
    bodyStyle,
    disableEscToClose,
    clickBackgroundToClose
  } = props;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.which === 27 && !disableEscToClose) {
        if (onCancel) {
          onCancel(e);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {  
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, [onCancel, disableEscToClose]);

  const handlePromptToCancelClick = (e) => {
    setPromptedToCancel(true);
    e.preventDefault();
  };

  const handleBackgroundClick = (e) => {
    const event = onCancel ? onCancel : onConfirm ? onConfirm : null;
    if (clickBackgroundToClose && event) {
      event(e);
    }
  };


  const modalClass = classNames("modal", {
    "modal--top-on-ipad": topOfScreenOnIpad,
    "modal--no-transition": notransition,
    [`modal--align-${align}`]: align,
  });

  const showTransition = !notransition;

  return (
    <TransitionGroup>
      <CSSTransition
        nodeRef={nodeRef}
        classNames="modal"
        timeout={{
          exit: showTransition ? 1000 : 0,
          enter: showTransition ? 1000 : 0,
        }}
        appear={showTransition}
      >
        <div className={modalClass} onClick={handleBackgroundClick}>
          <div
            open
            className="modal__dialog scrollable"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              width: bodyWidth ? bodyWidth : null,
              visibility: visible ? "visible" : "hidden",
            }}
          >
            {title ? (
              <header className="modal__header">
                <h1
                  className="modal__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </header>
            ) : null}
            <div className="modal__body" style={bodyStyle}>
              {body ? (
                <div dangerouslySetInnerHTML={{ __html: body }} />
              ) : null}
              {bodyRenderer ? bodyRenderer() : null}
              {children}
            </div>
            {!hideFooter ? (
              <footer className="modal__footer">
                {promptToCancel && !promptedToCancel ? (
                  <FlatButton
                    onClick={handlePromptToCancelClick}
                    disabled={confirmButtonDisabled}
                    loading={confirmButtonLoading}
                    type={promptToCancelType ? promptToCancelType : null}
                  >
                    {promptToCancelText ? promptToCancelText : "cancel"}
                  </FlatButton>
                ) : null}
                {onCancel && (!promptToCancel || promptedToCancel) ? (
                  <FlatButton
                    onClick={onCancel}
                    disabled={cancelButtonDisabled}
                    loading={cancelButtonLoading}
                    type={cancelButtonType ? cancelButtonType : null}
                  >
                    {cancelText ? cancelText : "cancel"}
                  </FlatButton>
                ) : null}
                {onConfirm ? (
                  <FlatButton
                    onClick={onConfirm}
                    disabled={confirmButtonDisabled}
                    loading={confirmButtonLoading}
                    type={confirmButtonType ? confirmButtonType : null}
                  >
                    {confirmText ? confirmText : "confirm"}
                  </FlatButton>
                ) : null}
              </footer>
            ) : null}
            {onCancel && showX ? (
              <button
                className="modal__close"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                <SvgIcon icon={CrossIcon} name="cross" />
              </button>
            ) : null}
            {loadingMessage ? (
              <div className="modal__loading">
                <p className="modal__loading-message">{loadingMessage}</p>
                <Loader />
              </div>
            ) : null}
            {showBackButton && onBackButtonClick ? (
              <button className="modal__back" onClick={onBackButtonClick}>
                <SvgIcon icon={ArrowIcon} name="arrow" />
              </button>
            ) : null}
          </div>
          {modal ? <div className="modal__background" /> : null}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}
