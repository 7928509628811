import React from "react";
import { createRoot } from 'react-dom/client';
import { initAuth } from "./core/auth";
import configureStore from "./core/store";

import Root from "./views/root";

const store = configureStore();
const rootElement = document.getElementById('book-creator-mission-control');

const root = createRoot(rootElement);

initAuth(store.dispatch)
  .then(() => root.render(<Root store={store} />))
  .catch((error) => console.error(error)); // eslint-disable-line no-console
