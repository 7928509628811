import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import Loader from "../ui/loader";
import "./flat.scss";

function FlatButton(props) {
  const {
    type,
    iconPosition,
    css,
    onClick,
    link,
    children,
    title,
    style,
    icon,
    iconName,
    loading,
    disabled,
  } = props;

  const cssClass = classNames("flat-button", {
    "flat-button--negative": type && type === "negative",
    "flat-button--active": type && type === "active",
    "flat-button--primary": type && type === "primary",
    "flat-button--secondary": type && type === "secondary",
    "flat-button--disabled": disabled,
    "flat-button--icon-right": iconPosition && iconPosition === "right",
    "flat-button--loading": loading,
    "flat-button--no-icon": !icon,
    "flat-button--icon-only": !children,
    [`${css}`]: css,
  });

  const opts = {
    className: cssClass,
    title,
    style,
    disabled,
  };

  if (link) {
    return (
      <Link {...opts} to={link}>
        {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
        {loading ? <Loader center tiny /> : null}
        {children ? (
          <span className="flat-button__text">{children}</span>
        ) : null}
      </Link>
    );
  } else {
    return (
      <button
        {...opts}
        onClick={onClick}
        type={type === "submit" ? type : "button"}
      >
        {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
        {loading ? <Loader center tiny /> : null}
        {children ? (
          <span className="flat-button__text">{children}</span>
        ) : null}
      </button>
    );
  }
}

export default FlatButton;
