import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { getEmailError } from "../../../utils/validators";
import { firebaseAuth } from "../../../core/firebase";
import Form from "../../components/forms/form";
import FormButton from "../../components/forms/form-button";
import TextBox from "../../components/forms/text-box";

const ForgottenPassword = (props) => {
  const params = useParams();

  const [email, setEmail] = useState(params.email);
  const [emailSent, setEmailSent] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState('');
  
  const { clearAuthError } = props;

  useEffect(() => {
    return () => {
      clearAuthError();
    }
  } , [clearAuthError]);

  const handleSubmit = (e) => {
    setSubmitted(true);

    if (isValid()) {
      setSubmitting(true);  
      sendResetEmail();
    }
    e.preventDefault();
  }

  const sendResetEmail = () => {
    firebaseAuth.sendPasswordResetEmail(email).then(
      () => {
        setEmailSent(true);
        setSubmitting(false)
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const isValid = () => {
    return !getEmailError(email);
  }


  const emailError = submitted && getEmailError(email);

  return (
    <Form header="Recover password" onSubmit={handleSubmit}>
      <Helmet title="Recover password" />
      {emailSent ? (
        <div className="form__content">
          <p>{`We have sent an email to ${email} with instructions to reset your password.`}</p>
        </div>
      ) : (
        <div className="form__content">
          <p>
            Get instructions sent to this email that explain how to reset your
            password
          </p>
          <TextBox
            label="Email"
            type="email"
            onChange={setEmail}
            value={email}
            autoFocus
            error={emailError}
          />
        </div>
      )}
      {emailSent ? (
        <div className="form__actions">
          <FormButton href="/sign-in" text="Sign in" />
        </div>
      ) : (
        <div className="form__actions">
          <FormButton flat href="/sign-in" text="Cancel" />
          <FormButton type="submit" text="Send" loading={submitting} />
        </div>
      )}
    </Form>
  );
}

export default ForgottenPassword;
