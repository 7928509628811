import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import MiniMenu from "../mini-menu";
import SvgIcon from "../../ui/svg-icon";
import MoreOptionsIcon from "../../../../images/svg/more-options.svg";
import "./index.scss";

const MoreOptionsMenu = (props) => {
  const [open, setOpen] = useState(false);
  const [timeout, setTimeoutState] = useState(null);
  const isMounted = useRef(true);

  const { children, width, compact, style, textonly, onToggleClick, onClose } = props;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleToggleClick = (e) => {
    setOpen(!open);
    if (onToggleClick) {
      onToggleClick(e);
    }
    e.stopPropagation();
    e.preventDefault();
  };

  const handleMouseLeave = () => {
    if (open && !timeout) {
      setTimeoutState(window.setTimeout(closeMenu, 2000));
    }
  };

  const handleMouseEnter = () => {
    window.clearTimeout(timeout);
    setTimeoutState(null);
  };

  const closeMenu = () => {
    if (onClose) {
      onClose();
    }
    if(isMounted.current){
      setOpen(false);
    }
  };

  const containerClass = classNames("more-options", "more-options--parent", {
    "more-options--active": open,
  });

  return (
    <div
      className={containerClass}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button onClick={handleToggleClick} className="more-options__toggle">
        <SvgIcon icon={MoreOptionsIcon}  name="more-options" />
      </button>
      {open && children ? (
        <MiniMenu
          top
          right
          inside
          compact={compact}
          textonly={textonly}
          width={width}
          onClose={closeMenu}
          onItemClick={closeMenu}
        >
          {children}
        </MiniMenu>
      ) : null}
    </div>
  );
};

export default MoreOptionsMenu;