import React from "react";
import Group from "./group";

function LibraryResultUsers(props) {
  const { users } = props;

  let userArray = Object.entries(users).map(([id, data]) => {
    return {
      id,
      ...data,
    };
  });
  let owner = userArray.filter(user => user.userRole === 1)[0];
  let oldOwner = userArray.filter(user => user.userRole === 99)[0];
  let coTeachers = userArray.filter(user => user.userRole === 400);
  let visitingAdmins = userArray.filter(user => user.userRole === 399);
  let guestTeachers = userArray.filter(user => user.userRole === 600);
  let students = userArray.filter(user => user.userRole === 800);
  let readOnlyUsers = userArray.filter(user => user.userRole === 900);

  return (
    <div>
      <Group name="Owner" users={[owner]} />
      {oldOwner ? <Group name="Old owner" users={[oldOwner]} /> : null}
      {coTeachers.length ? (
        <Group name="Co-teachers" users={coTeachers} />
      ) : null}
      {guestTeachers.length ? (
        <Group name="Teacher authors" users={guestTeachers} />
      ) : null}
      {students.length ? <Group name="Students" users={students} /> : null}
      {readOnlyUsers.length ? (
        <Group name="Read only" users={readOnlyUsers} />
      ) : null}
      {visitingAdmins.length ? (
        <Group name="Visiting admins" users={visitingAdmins} />
      ) : null}
    </div>
  );
}

export default LibraryResultUsers;
