import React, { useEffect, useState } from "react";
import { NOUN_PROJECT_DOMAIN } from "../../../../../config";
import { useLocation, useNavigate } from "react-router-dom";

import useFetch from "use-http";
import SearchField from "../../../forms/search-field";
import Loader from "../../../ui/loader";
import Results from "./results";
import Message from "../../../ui/message";

function NounProjectSearch(props) {
  const { blockedIDs, blockedSlugs } = props;

  const [results, setResults] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const { get, response, error } = useFetch(NOUN_PROJECT_DOMAIN, {
    credentials: "include",
  });

  const handleSearch = async (searchTerm) => {
    navigate(`/image-search/noun-project/?search=${searchTerm}`);
  };

  useEffect(() => {
    const doSearch = async () => {
      const params = new URLSearchParams(location.search);
      const searchTerm = params.get("search");
      setResults(null);
      setSearching(true);
      setSearchedTerm(searchTerm);
      const icons = await get(encodeURI(`search?term=${searchTerm}`));
      setSearching(false);
      if (response.ok) {
        setResults(icons);
      } else if (response.status === 404) {
        setResults([]);
      }
    };
    if (location.search) {
      doSearch();
    }
  }, [location.search, get, response.ok, response.status]);

  return (
    <div>
      <SearchField
        onSubmit={handleSearch}
        placeholder="Search for icons"
        search={searchedTerm}
      />
      {error ? (
        <Message
          type="error"
          style={{ maxWidth: 500, margin: "0 auto", textAlign: "center" }}
        >
          {error.message}
        </Message>
      ) : null}
      {searching ? <Loader /> : null}
      {results && results.length === 0 ? (
        <p style={{ textAlign: "center" }}>No results found '{searchedTerm}'</p>
      ) : null}
      {results && results.length > 0 ? (
        <Results
          results={results}
          blockedIDs={blockedIDs}
          blockedSlugs={blockedSlugs}
        />
      ) : null}
    </div>
  );
}

export default NounProjectSearch;
