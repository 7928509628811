import React, { useState, useRef, useCallback } from 'react';
import classNames from "classnames";
import ReactSelect, {components} from "react-select";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import TriangleIcon from "../../../../images/svg/triangle.svg";
import "./index.scss";

const Dropdown = ({
  value,
  options = [],
  className,
  label,
  menuPlacement,
  placeholder,
  triangle,
  width,
  open,
  onChange,
}) => {
  const [openAbove, setOpenAbove] = useState(false);
  const selectRef = useRef(null);

  const handleOpen = useCallback(() => {
    if (selectRef.current) {
      const { top } = selectRef.current.inputRef.getBoundingClientRect();
      const h = window.innerHeight;
      const isAbove = top + 100 > h;
      setOpenAbove(isAbove);
    }
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (e) {
        onChange(e);
      }
    },
    [onChange]
  );

  const DropdownIndicator = ({ children, innerRef, ...props }) => (
    <components.DropdownIndicator {...props}>
        {triangle ? <SvgIcon ref={innerRef} icon={TriangleIcon} /> : <SvgIcon icon={ChevronIcon} />} 
    </components.DropdownIndicator>
  );

  const containerClass = classNames("dropdown", {
    "dropdown--with-label": !!label,
  });

  let cssClass = classNames("Select", {
    [`${className}`]: className,
    triangle: triangle,
    "open-above": openAbove,
  });

  return (
    <div className={containerClass}>
      {label ? <label className="dropdown__label">{label}</label> : null}
      <ReactSelect
        ref={selectRef}
        onChange={handleChange}
        value={value}
        className={cssClass}
        classNamePrefix={"Select"}
        isSearchable={false}
        placeholder={placeholder}
        components={{ DropdownIndicator }}
        onMenuOpen={handleOpen}
        menuPlacement={menuPlacement}
        isClearable={false}
        width={width}
        openMenuOnFocus={open}
        options={options.map(o =>
          o.label ? o : { value: o.value, label: o.value }
        )}
      />
    </div>
  );
};

export default Dropdown;