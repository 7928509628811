import React, { Fragment } from "react";
import classNames from "classnames";
import ID from "./id";
import Tag from "./tag";
import "./index.scss";

function PixabayImageSearchResult(props) {
  const {
    blockedById,
    blockedByTag,
    blockedTags = [],
    link,
    blockById,
    unBlockById,
    blockByTag,
    unBlockByTag,
    obscureblocked,
    id,
    tags,
  } = props;

  const blocked = blockedByTag || blockedById;

  const className = classNames("pixabay-image-search-result", {
    "pixabay-image-search-result--blocked": blocked && obscureblocked,
  });

  let blockedText;

  if (blockedById && blockedByTag) {
    blockedText = "Blocked by ID and tag";
  } else if (blockedById) {
    blockedText = "Blocked by ID";
  } else if (blockedByTag) {
    blockedText = "Blocked by tag";
  }

  return (
    <div className={className}>
      <div className="pixabay-image-search-result__preview">
        <img className="pixabay-image-search-result__image" src={link} alt="" />
        {blocked && obscureblocked ? (
          <Fragment>
            <img
              src="https://storage.bookcreator.com/static/emoji/1f6ab.svg"
              alt=""
              className="pixabay-image-search-result__emoji"
            />
            <span className="pixabay-image-search-result__blocked">
              {blockedText}
            </span>
          </Fragment>
        ) : null}
      </div>
      <ID
        id={id}
        blocked={blockedById}
        onToggle={(id) => (blockedById ? unBlockById(id) : blockById(id))}
      />
      <div className="pixabay-image-search-result__tags">
        {tags.map((tag) => (
          <Tag
            key={tag}
            blockByTag={blockByTag}
            unBlockByTag={unBlockByTag}
            onToggle={() =>
              blockedTags.includes(tag) ? unBlockByTag(tag) : blockByTag(tag)
            }
            tag={tag}
            blocked={blockedTags.includes(tag)}
          />
        ))}
      </div>
    </div>
  );
}

export default PixabayImageSearchResult;
