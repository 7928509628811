import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getHasEngineerPrivileges } from "../../../core/auth";
import { useLocation } from "react-router-dom";

import { authActions } from "../../../core/auth";
import Nav from "./";

function NavContainer(props) {
  const location = useLocation();
  return <Nav {...props} location={location} />;
}

const mapDispatchToProps = {
  ...authActions,
};

const mapStateToProps = createSelector(
  getHasEngineerPrivileges,
  hasEngineerPrivileges => ({
    hasEngineerPrivileges,
  })
);

export default connect(mapStateToProps, mapDispatchToProps)(NavContainer);
