import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchField from "../../forms/search-field";

function LibrarySearch(props) {
  const { authToken,  searchLibraries } = props;
  const navigate = useNavigate();
  const {libraryId} = useParams();

  const handleSearch = (libraryId) => {
    let trimmedLibraryId = libraryId.trim();
    if (!trimmedLibraryId.length || trimmedLibraryId.length < 2) {
      return;
    }
    searchLibraries({ libraryId: trimmedLibraryId });
    navigate(`/libraries/${trimmedLibraryId}`);
  };

  return (
    <SearchField
      authToken={authToken}
      onSubmit={handleSearch}
      search={libraryId}
      placeholder="Search by library ID"
    />
  );
}

export default LibrarySearch;
