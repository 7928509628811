import React, { useEffect } from "react";
import Section from "../section";
import Switch from "src/views/components/forms/switch";

interface OrganisationFeatures {
  orgLibraries?: boolean;
  studentPortfolios?: boolean;
  visitLibraries?: boolean;
}

interface OrganisationFeaturesProps {
  features: OrganisationFeatures;
  onChange: (features: OrganisationFeatures) => void;
  orgLibrariesDisabled?: boolean;
  studentPortfoliosDisabled?: boolean;
}

export default function OrganisationFeaturesPicker(
  props: OrganisationFeaturesProps
) {
  const {
    features,
    onChange,
    orgLibrariesDisabled,
    studentPortfoliosDisabled,
  } = props;

  // Turn portfolios off when control becomes disabled
  useEffect(() => {
    if (studentPortfoliosDisabled && features.studentPortfolios) {
      onChange({ ...features, studentPortfolios: null });
    }
  }, [studentPortfoliosDisabled, features, onChange]);

  const handleOrgLibrariesChange = (value: boolean) => {
    onChange({ ...features, orgLibraries: value || null });
  };

  const handleVisitLibrariesChange = (value: boolean) => {
    onChange({ ...features, visitLibraries: value || null });
  };

  const handleStudentPortfoliosChange = (value: boolean) => {
    onChange({ ...features, studentPortfolios: value || null });
  };

  return (
    <Section title="Features">
      <div className="organisation-features">
        <Switch
          label="Organisation Libraries"
          value={features?.orgLibraries ?? false}
          onChange={handleOrgLibrariesChange}
          disabled={orgLibrariesDisabled}
          id="feature-switch--org-libraries"
          flush
          notopborder
          tooltip={
            studentPortfoliosDisabled
              ? "Org libraries is only available to domain orgs"
              : null
          }
        />
        <Switch
          label="Visit Libraries"
          value={features?.visitLibraries ?? false}
          onChange={handleVisitLibrariesChange}
          id="feature-switch--visit-libraries"
          flush
          nobottomborder
        />
        <Switch
          label="Student Portfolios"
          value={features?.studentPortfolios ?? false}
          onChange={handleStudentPortfoliosChange}
          disabled={studentPortfoliosDisabled}
          flush
          id="feature-switch--student-portfolios"
          nobottomborder
          tooltip={
            studentPortfoliosDisabled
              ? "Only domain orgs with student domains set can turn portfolios on"
              : null
          }
        />
      </div>
    </Section>
  );
}
