import React, { useEffect, useRef } from "react";
import JSONFormatter from "json-formatter-js";
import Modal from "../../../modal";
import "./index.scss";

const AllUserData = ({ data, onClose }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const settings = {
      animateOpen: false,
      animateClose: false,
    };
    const formatter = new JSONFormatter(data, 1, settings);
    if (containerRef.current) {
      containerRef.current.appendChild(formatter.render());
    }
  }, [data]);

  return (
    <Modal width={1000} showX onCancel={onClose} cancelText="Done">
      <div className="all-user-data" ref={containerRef}></div>
    </Modal>
  );
};

export default AllUserData;
