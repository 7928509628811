import React from "react";
import { Helmet } from "react-helmet"
import Tags from "../../../components/image-search/tags";

function BlockedTagsPage(props) {
  return (
    <div>
      <Helmet title="Tags" />
      <Tags />
    </div>
  );
}

export default BlockedTagsPage;
