import React, { useState, useEffect, useRef } from "react";
import { getInitialsFromName } from "../../../utils";
import "./index.scss";

const Avatar = ({ url, name, size }) => {
  const [imageFailed, setImageFailed] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    if (url) {
      let image = new Image();
      image.src = url;
      image.onload = () => {
        if (isMounted.current) {
          setImageUrl(url);
        }
      };
      image.onerror = () => {
        if (isMounted.current) {
          setImageFailed(true);
        }
      };
    }

    return () => {
      isMounted.current = false;
    };
  }, [url]);

  useEffect(() => {
    if (imageUrl && !url) {
      setImageUrl(null);
    }
  }, [url, imageUrl]);

  const style = {
    width: size,
    height: size,
  };

  const initialsScale = size ? size / 40 : 1;

    const initialsStyle = {
      transform: size ? `scale(${initialsScale})` : null,
    };

  return (
    <div className="avatar" style={style}>
      {imageUrl && !imageFailed ? (
        <img src={imageUrl} alt={name} onError={e => {
          setImageFailed(true);
        }}/>
      ) : (
        <div className="avatar-initials" style={initialsStyle}>
          {getInitialsFromName(name)}
        </div>
      )}
    </div>
  );
};

export default Avatar;