import React, { useState } from "react";
import Modal from "../../../../modal";
import DomainEditorItem from "./item";
import { OrgDomain } from "..";
import Button from "../../../../button";
import { isSameDomain } from "../../helpers.js";
import "./index.scss";

interface DomainEditorProps {
  addMode?: boolean;
  orgEditMode?: boolean;
  domains: OrgDomain[];
  onSave: (domains: OrgDomain[]) => void;
  onClose: () => void;
}

export default function DomainEditor(props: DomainEditorProps) {
  const { addMode, onClose, onSave, orgEditMode } = props;

  const [domains, setDomains] = useState<OrgDomain[]>(props.domains);
  const [editingDomain, setEditingDomain] = useState<OrgDomain | null>(null);
  const [adding, setAdding] = useState(!!addMode);

  const handleSubmit = () => {
    onSave(domains);
    onClose();
  };

  const handleSave = ({
    domain,
    oldDomain,
  }: {
    domain: OrgDomain;
    oldDomain: OrgDomain;
  }) => {
    let domainUpdate = [...domains];
    let domainSaved = false;

    // look for ids on existing records or find item by domain and local
    // if oldDomain props are undefined means it's a new item
    domainUpdate = domainUpdate.map(d => {
      const existingItem = d.id && d.id === domain.id;

      const unsavedItemUpdated =
        d.domain.toLowerCase() === oldDomain.domain?.toLowerCase() &&
        d.local === oldDomain.local;

      if (existingItem || unsavedItemUpdated) {
        domainSaved = true;
        return domain;
      }
      return d;
    });

    if (!domainSaved) {
      domainUpdate.push(domain);
    }
    setDomains(domainUpdate);
    if (adding) {
      setAdding(false);
    }
    if (editingDomain) {
      setEditingDomain(null);
    }
  };

  return (
    <Modal
      modal
      onCancel={onClose}
      cancelText="Cancel"
      onConfirm={handleSubmit}
      confirmText={orgEditMode ? "Done" : "Save"}
      confirmButtonDisabled={adding || editingDomain}>
      <div className="domain-editor">
        <h2 className="domain-editor__title">
          Organisation user domains
          {!adding && !editingDomain && (
            <Button
              onClick={() => setAdding(true)}
              type="button"
              small
              style={{ marginLeft: 10 }}>
              Add
            </Button>
          )}
        </h2>

        {domains.map((domain) => (
          <DomainEditorItem
            {...domain}
            orgEditMode={orgEditMode}
            domains={domains}
            editing={isSameDomain(editingDomain, domain)}
            editDisabled={adding}
            setEditing={setEditingDomain}
            key={`${domain.domain}${domain.local}`}
            onSave={handleSave}
            onDelete={() => {
              setDomains([...domains.filter(d => !isSameDomain(d, domain))]);
            }}
            onCancel={() => setEditingDomain(null)}
          />
        ))}
        {adding ? (
          <DomainEditorItem
            editing
            domains={domains}
            onSave={handleSave}
            onCancel={() => setAdding(false)}
          />
        ) : null}
        {domains.length === 0 && !adding && (
          <p className="domain-editor__empty">No domains added</p>
        )}
      </div>
    </Modal>
  );
}
