import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"
import { Link, useNavigate, useParams } from "react-router-dom";
import { getEmailError, getPasswordError } from "../../../utils/validators";
import { firebaseAuth } from "../../../core/firebase";
import Form from "../../components/forms/form";
import FormButton from "../../components/forms/form-button";
import SignInButton from "./sign-in-button";
import TextBox from "../../components/forms/text-box";
import Password from "../../components/forms/password";
import Message from "../ui/message";

const SignInWithEmail = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState(params.email);
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState('');
  const [submitting, setSubmitting] = useState('');
  const [alreadyRegistered, setAlreadyRegistered] = useState('');
  const [alreadyRegisteredWithGoogle, setAlreadyRegisteredWithGoogle] = useState('');

  const { signInWithEmailAndPassword, signInWithGoogle, auth, clearAuthError } = props;

  useEffect(() => {
    return () => {
      clearAuthError();
    }
  } , [clearAuthError]);

  useEffect(() => {
    if (auth.error) {
      setSubmitting(false);
    }
  } , [auth]);


  const updateEmail = (nextEmail) => {
    clearAuthError();
    setEmail(nextEmail);
  }

  const updatePassword = (nextPassword) => {
    clearAuthError();
    setPassword(nextPassword);
  }

  const checkIfUserExists = ()=> {
    return new Promise((resolve, reject) => {
      firebaseAuth
        .fetchSignInMethodsForEmail(email)
        .catch((error) => {
          resolve(false);
        })
        .then((data) => {
          resolve(data);
        });
    });
  }

  const handleSubmit = (e)  =>{
    setSubmitted(true); 
    e.preventDefault();

    if (!isValid()) {
      return;
    }
    if (alreadyRegistered) {
      setSubmitting(true);
      signInWithEmailAndPassword({
        email,
        password,
      });
    } else if (!alreadyRegisteredWithGoogle) {
      checkIfUserExists().then((data) => {
        if (data && data.includes("password")) {
          setAlreadyRegistered(true);
          setSubmitted(false);
        } else if (data && data.includes("google.com")) {
          setAlreadyRegisteredWithGoogle(true);
        } else {
          navigate(`/sign-in/register/${email}`);
        }
      });
    }
  }

  const isValid = () => {
    const { error } = auth;

    if (alreadyRegistered) {
      return !error && !getEmailError(email) && !getPasswordError(password);
    } else {
      return !error && !getEmailError(email);
    }
  }


  const { error } = auth;

  let emailError = submitted && getEmailError(email);
  let passwordError = submitted && getPasswordError(password);

  if (error && error.code) {
    switch (error.code) {
      case "auth/wrong-password":
        passwordError = "Incorrect password";
        break;
      case "auth/user-not-found":
        emailError = "That email address doesn't match an existing account";
        break;
      case "auth/invalid-email":
        emailError = "Invalid email address";
        break;
      case "auth/user-disabled":
        emailError = "This account has been disabled";
        break;
      case "auth/too-many-requests":
        passwordError =
          "You have entered an incorrect password too many times. Please try again in a few minutes.";
        break;
      default:
        passwordError = "There was a problem signing in, please try again.";
        break;
    }
  }

  const submitText = alreadyRegistered ? "Sign in" : "Next";

  const cancelLink = "/sign-in";
  const forgottenPasswordLink = "/sign-in/forgotten-password";

  return (
    <Form header="Sign in with email" onSubmit={handleSubmit}>
      <Helmet title="Sign in with an email address" />
      <div className="form__content">
        {alreadyRegisteredWithGoogle ? (
          <div>
            <p>
              That email address has already been registered with a Google
              account
            </p>
            <SignInButton
              text="Sign in with Google"
              onClick={signInWithGoogle}
              provider="google"
            />
          </div>
        ) : (
          <TextBox
            label="Email"
            type="email"
            name="email"
            onChange={updateEmail}
            value={email}
            autoFocus
            error={emailError}
          />
        )}
        {alreadyRegistered ? (
          <Password
            label="Password"
            name="password"
            onChange={updatePassword}
            value={password}
            autoFocus
            error={passwordError}
          />
        ) : null}
        {error && error.code === "auth/internal-error" ? (
          <Message type="error" center>
            {error.message}
          </Message>
        ) : null}
      </div>
      <div className="form__actions">
        {alreadyRegistered ? (
          <small className="form__actions-smallprint">
            <Link to={forgottenPasswordLink}>Forgotten password?</Link>
          </small>
        ) : null}
        <FormButton flat href={cancelLink} text="Cancel" />
        {!alreadyRegisteredWithGoogle ? (
          <FormButton type="submit" text={submitText} loading={submitting} />
        ) : null}
      </div>
    </Form>
  );
}

export default SignInWithEmail;
