import React, { useState } from "react";
import Button from "../../button";
import "./index.scss";

const MAX_CODES = 10;

export default function PromoCodeGeneratorForm (props) {
  const [codesToGenerate, setCodesToGenerate] = useState(1);

  const handleInputChange = (e) => {
    const val = parseInt(e.target.value, 10);
    const minVal = val ? val : null;
    setCodesToGenerate( minVal ? Math.min(minVal, MAX_CODES) : "");
  };

  const handleSubmit = (e) => {
    props.onSubmit(codesToGenerate);
    e.preventDefault();
  };

  return (
    <form className="generator-form" onSubmit={handleSubmit}>
      <label className="generator-form__label">Generate</label>
      <input
        type="number"
        className="generator-form__input"
        value={codesToGenerate}
        max={MAX_CODES}
        onChange={handleInputChange}
      />
      <label className="generator-form__unit">codes</label>
      <Button type="submit" small>
        Go
      </Button>
    </form>
  );
}
