import React from "react";
import PropTypes from "prop-types";
import { Provider, useSelector } from "react-redux";
import {
  Route,
  BrowserRouter ,
  Navigate,
  Routes,
  useLocation,
} from "react-router-dom";
import { isAuthenticated } from "../core/auth";
import App from "./app";
import SignIn from "./pages/sign-in";

export default function Root({ store }) {
  return (
    <Provider store={store}>
      {/* ConnectedRouter will use the store from Provider automatically
        TODO: change to v6 createBrowserRouter
      */}
      <BrowserRouter store={store}>
        <Routes>
          <Route path="/sign-in/*" element={<SignIn/>} />
          <Route path="*" element={<RequireAuth />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

function RequireAuth() {
  const location = useLocation();
  const isAuthed = useSelector(isAuthenticated);
  return isAuthed ? <App /> : <Navigate to="/sign-in" replace state={{ from: location }} />;
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};