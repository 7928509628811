import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { BOOKCREATOR_API_URL, BOOKCREATOR_APP_URL } from "../../../../config";
import { usersActions } from "../../../../core/users";
import { notificationsActions } from "../../../../core/notifications";
import superagent from "superagent";
import Modal from "../../modal";
import Loader from "../../ui/loader";

const UserToImpersonateModal = (props) => {
  const [token, setToken] = useState(null); 
  const { userToImpersonate, addErrorMessage, setShowImpersonateUserModal } = props;

  useEffect(() => {
    if(userToImpersonate) {
      const { uid } = userToImpersonate;
      superagent
        .post(`${BOOKCREATOR_API_URL}/admin-tools/v2/${uid}/auth`)
        .withCredentials()
        .end((err, res) => {
          if (err) {
            addErrorMessage("Could not sign in as that user");
          } else {
            setToken(res.body);
          }
        });
    }
  }, [addErrorMessage, userToImpersonate]);

  
  const handleCancelClick = (e) => {
    setShowImpersonateUserModal(null);
  };

  const link = () => {
    return `${BOOKCREATOR_APP_URL}/sign-in-with-token/${token}`;
  }

  const { displayName, email, loading } = userToImpersonate;

  const textAreaStyle = {
    display: "block",
    width: "100%",
    fontSize: 14,
    padding: "5px 10px",
    height: 220,
    margin: "40px auto 0",
    wordBreak: "break-all",
  };

  return (
    <Modal
      modal
      title={loading ? "Signing in..." : "Sign in as user?"}
      hideFooter={loading}
      onCancel={handleCancelClick}
      width={800}
      cancelText="Close"
    >
      {loading || !token ? (
        <div style={{ height: 70 }}>
          <Loader center />
        </div>
      ) : (
        <div>
          You are about to sign in as <strong>{displayName}</strong>{" "}
          {email ? `[${email}]` : ""}.
          <br />
          <br />
          Do you have permission from this user to sign in to their account?
          <br />
          <br />
          Copy and paste this link in an incognito window to sign in:
          <textarea style={textAreaStyle} defaultValue={link()}></textarea>
        </div>
      )}
    </Modal>
  );
};

const mapDispatchToProps = {
  ...notificationsActions,
  ...usersActions,
};

export default connect(null, mapDispatchToProps)(UserToImpersonateModal);