import React, { useEffect, useState } from 'react';
import {
  BOOKCREATOR_API_URL,
  BOOKCREATOR_PROMO_CODE_PREFIX,
} from "../../../../../config";
import Loader from "../../../ui/loader";
import superagent from "superagent";
import "./index.scss";

export default function Code({ ready, onLoad, onError  }) {
  const [loading, setLoading] = useState(true); 
  const [promoCode, setPromoCode] = useState(null); 

  useEffect(() => {
    if(ready && !promoCode) {
      superagent
        .post(`${BOOKCREATOR_API_URL}/admin-tools/v2/me/promo-codes`)
        .withCredentials()
        .end((err, res) => {
          if (err) {
            onError(err);
          } else {
            onLoad(res.body);
            setPromoCode(res.body);
            setLoading(false);
          }
        });
    }
  }, [ready, onLoad, onError, promoCode]);

  return (
    <div className="promo-code">
      {loading ? <Loader small /> : null}
      {promoCode ? (
        <span className="promo-code__code">
          {BOOKCREATOR_PROMO_CODE_PREFIX}promocode/BC{promoCode}
        </span>
      ) : null}
    </div>
  );
}
