import React, { Component } from "react";
import { Helmet } from "react-helmet"
import { connect } from "react-redux";
import Header from "../../components/header";
import Content from "../../components/content";
import PromoCodeGenerator from "../../components/promo-code-generator";
import "./index.scss";

class PromoCodes extends Component {
  render() {
    const title = `Promo Codes`;

    return (
      <div>
        <Helmet title={title} />
        <Header title={title} />
        <Content>
          <PromoCodeGenerator />
        </Content>
      </div>
    );
  }
}

export default connect(null, null)(PromoCodes);
