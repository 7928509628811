import React from "react";
import PropTypes from "prop-types";
import Stat from "./stat";
import TeacherIcon from "../../../../images/svg/teacher.svg";
import StudentIcon from "../../../../images/svg/student.svg";
import BookSpreadIcon from "../../../../images/svg/book-spread.svg";
import LibraryIcon from "../../../../images/svg/library.svg";
import "./index.scss";

const Stats = props => {
  const { domain, hiddenCharts = [], auth } = props;

  return (
    <div className="stats">
      {!hiddenCharts.includes("teacherCount") ? (
        <Stat
          title="Teachers"
          icon={TeacherIcon}
          iconName="teacher"
          endpoint="teachers/count"
          auth={auth}
          domain={domain}
        />
      ) : null}
      {!hiddenCharts.includes("studentCount") ? (
        <Stat
          title="Students"
          icon={StudentIcon}
          iconName="student"
          endpoint="students/count"
          auth={auth}
          domain={domain}
        />
      ) : null}
      {!hiddenCharts.includes("libraryCount") ? (
        <Stat
          title="Libraries Created"
          icon={LibraryIcon}
          iconName="library"
          endpoint="libraries/count"
          auth={auth}
          domain={domain}
        />
      ) : null}
      {!hiddenCharts.includes("bookCount") ? (
        <Stat
          title="Books Created"
          icon={BookSpreadIcon}
          iconName="books"
          endpoint="books/count"
          auth={auth}
          domain={domain}
        />
      ) : null}
    </div>
  );
};

Stats.propTypes = {
  domain: PropTypes.string.isRequired,
  auth: PropTypes.shape({
    expiresAt: PropTypes.number,
    getAuth: PropTypes.func,
    userUrls: PropTypes.string,
  }),
};

export default Stats;
