import { createSelector } from "reselect";

export function getAnalyticsState(state) {
  return state.analytics;
}

export const getAnalyticsResourceAuthExpiry = createSelector(
  getAnalyticsState,
  state => {
    return state.resourceAuthExpires;
  }
);

export const getAnalyticsSignedURLs = createSelector(
  getAnalyticsState,
  state => {
    return state.signedURLs;
  }
);

export const getUserAnalyticsSignedURLs = createSelector(
  getAnalyticsState,
  state => {
    return state.userSignedURLs;
  }
);

export const getIsLoadingResource = createSelector(getAnalyticsState, state => {
  return state.isLoading;
});
