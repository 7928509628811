import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { closestParent } from "../../../../utils";
import "./index.scss";

const MiniMenu = (props) => {
  const [visible, setVisible] = useState(false);
  const [timer, setTimer] = useState(null);
  const isMounted = useRef(true);
  const containerRef = useRef(null);

  const { top, right, bottom, left, inside, position, dropdown,
     pinned, dontHide, onClose, hideAfterMouseLeave  } = props;

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (
        !closestParent(e.target, ".mini-menu") &&
        !closestParent(e.target, ".button-menu--active", true) &&
        !closestParent(e.target, ".published-badge", true) &&
        !closestParent(e.target, ".page-control", true) &&
        !closestParent(e.target, ".analytics-settings", true) &&
        !closestParent(e.target, ".mini-account__toggle", true) &&
        !dontHide && onClose
      ) {
        onClose();
      }
    };

    const timeout = pinned ? 0 : 50;
    const timer = setTimeout(() => {
      if (isMounted.current) {
        setVisible(true);
      }
    }, timeout);
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      isMounted.current = false;
      clearTimeout(timer);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("touchstart", handleMouseDown);
    };
  }, [pinned, onClose, dontHide]);


  const handleMouseLeave = () => {
    if (
      !timer &&
      !dontHide &&
      hideAfterMouseLeave
    ) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          onClose();
        }
      }, 2000);
      setTimer(timer);
    }
  };

  const handleMouseEnter = () => {
    window.clearTimeout(timer);
    setTimer(null);
  };

  const getPosition = () => {
    let p;

    if (dropdown) {
      p = {
        top: "100%",
        left: "0",
        marginTop: 4,
      };
    } else {
      p = {
        top: position
          ? position.y
          : top
          ? inside
            ? 0
            : "100%"
          : bottom
          ? null
          : 0,
        right: position
          ? null
          : inside
          ? right
            ? 0
            : null
          : right
          ? null
          : "100%",
        bottom: position ? null : bottom ? "100%" : null,
        left: position
          ? position.x
          : inside
          ? left
            ? 0
            : null
          : left
          ? null
          : "100%",
        marginLeft: inside || position ? null : right ? 14 : left ? -14 : null,
        marginBottom: bottom && !position ? 10 : 0,
        marginTop: top && !position && !inside ? 10 : 0,
      };
    }

    // Don't let menu show off screen
    if (isMounted.current && position) {
      const MENU_MARGIN_FROM_EDGE = 5;
      const height = containerRef.current.offsetHeight;
      const width = containerRef.current.offsetWidth;
      const screenBottom = window.innerHeight;
      const screenRight = window.innerWidth;
      if (p.top + height > screenBottom) {
        p.top = screenBottom - height - MENU_MARGIN_FROM_EDGE;
      }
      if (p.left + width > screenRight) {
        p.left = screenRight - width - MENU_MARGIN_FROM_EDGE;
      }
    }

    return p;
  }

  const {
    children,
    onItemClick,
    width,
    beak,
    center,
    compact,
    textonly,
    origin,
  } = props;

  const cssClass = classNames("mini-menu", {
    "mini-menu--visible": visible,
    "mini-menu--compact": compact,
    "mini-menu--textonly": textonly,
    "mini-menu--dropdown": dropdown,
  });

  const menuStyle = {
    ...getPosition(),
    width: width ? width : null,
  };

  let frameOrigin;

  if (origin) {
    frameOrigin = origin;
  } else {
    frameOrigin =
      (inside && left) || (!inside && right) || position
        ? "0"
        : center
        ? "50%"
        : "100%";
    if (bottom) {
      frameOrigin += " 100%";
    } else {
      frameOrigin += " 0";
    }
  }

  const frameStyle = {
    top: top ? 0 : bottom ? null : 0,
    right: right ? 0 : null,
    bottom: bottom ? 0 : null,
    left: left ? 0 : null,
    transformOrigin: frameOrigin,
    WebkitTransformOrigin: frameOrigin,
    width: width ? width : null,
  };

  return (
    <div
      className={cssClass}
      style={menuStyle}
      onClick={(e) => {
        e.stopPropagation();
        if (onItemClick) {
          onItemClick(e);
        }
      }}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      ref={containerRef}
    >
      <div className="mini-menu__frame" style={frameStyle}></div>
      <div className="mini-menu__content">
        {beak ? <div className="mini-menu__beak" /> : null}
        {children}
      </div>
    </div>
  );
};

export default MiniMenu;