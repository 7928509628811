import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet"
import {
  getEmailError,
  getPasswordError,
  getFullNameError,
} from "../../../utils/validators";
import { TERMS_OF_SERVICE_URL } from "../../../constants";
import Form from "../forms/form";
import FormButton from "../forms/form-button";
import TextBox from "../forms/text-box";
import Password from "../forms/password";
import Message from "../ui/message";

function Register(props) {
  const { email } = useParams();
  const [emailAddress, setEmailAddress] = useState(email ??"");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      props.clearAuthError();
    }
  }, [props]);


  const handleSubmit = (e) => {

    const { createUserWithEmailAndPassword } = props;

    setSubmitted(true);

    if (isValid()) {
      setSubmitting(true);

      createUserWithEmailAndPassword({
        email: emailAddress,
        displayName: fullName,
        password: password,
      });
    }
    e.preventDefault();
  }

  const isValid =() => {
    return (
      !getEmailError(emailAddress) &&
      !getPasswordError(password) &&
      !getFullNameError(fullName)
    );
  }
    const { error } = props.auth.toJS();

    const emailError = submitted && getEmailError(emailAddress);
    const fullNameError = submitted && getFullNameError(fullName);
    const passwordError = submitted && getPasswordError(password);

    const accountExists = error && error.code === "auth/email-already-in-use";

    return (
      <Form
        header="Create account"
        onSubmit={handleSubmit}
        autocomplete={false}
      >
        <Helmet title="Register an account to use Book Creator" />
        <div className="form__content">
          <TextBox
            label="Email"
            type="email"
            onChange={setEmailAddress}
            value={emailAddress}
            autoFocus={!email}
            error={emailError}
          />

          <TextBox
            label="First and last name"
            onChange={setFullName}
            value={fullName}
            autoFocus={email}
            error={fullNameError}
          />

          <Password
            label="Password"
            onChange={setPassword}
            value={password}
            error={passwordError}
          />
        </div>
        <div className="form__actions">
          <small className="form__actions-smallprint">
            <span>
              By submitting this form, you are indicating that you agree to the{" "}
            </span>
            <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            .
          </small>
          {error ? (
            <Message type="error" center>
              {error.message}
            </Message>
          ) : null}
          <FormButton flat href="/sign-in" text="Cancel" />
          {accountExists ? (
            <FormButton flat href="/sign-in/email" text="Sign in" />
          ) : null}
          <FormButton
            type="submit"
            text="Submit"
            loading={submitting && !error}
          />
        </div>
      </Form>
    );
}

export default Register;
