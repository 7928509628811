import React, { useState, useEffect } from 'react';
import EyeIcon from "../../../images/svg/eye.svg";
import SvgIcon from "../ui/svg-icon";
import Input from "./input";

const Password =  React.forwardRef(({ autoFocus, onFocus, onBlur, onChange, id, name }, ref) => {
  const [plainText, setPlainText] = useState(false);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus, ref]);

  const togglePlainText = (e) => {
    setPlainText(!plainText);
    if (ref.current) {
      ref.current.focus();
    }
    e.preventDefault();
  };

  const opts = {
    onFocus,
    onBlur,
    onChange,
    id,
  };
  return (
    <div>
      <input
        {...opts}
        className="input__input input__input--has-toggle"
        type={plainText ? "text" : "password"}
        name={name}
        ref={ref}
        onChange={(e) => onChange(e.target.value)}
      />
      <button onClick={togglePlainText} className="input__toggle" >
        <SvgIcon icon={EyeIcon} />
      </button>
    </div>
  );
});

export default Input(Password);
