import React, { Component } from "react";
import { Helmet } from "react-helmet"
import { connect } from "react-redux";
import { Route, Routes, Navigate} from "react-router-dom";
import { organisationsActions } from "../../../core/organisations";
import OrganisationsTable from "../../components/organisations/organisations-table";
import AddEditOrganisation from "../../components/organisations/add-edit";
import DeletedOrganisations from "../../components/organisations/deleted";
import "./index.scss";

class Organisations extends Component {
  render() {
    const title = 'Organisations';

    return (
      <div>
        <Helmet title={title} />
          <Routes>
            <Route
              path={'/'}
              element={<OrganisationsTable/>}
            />
            <Route
              path={'/new'}
              element={<AddEditOrganisation/>}
            />
            <Route
              path={':orgId'}
              element={<Navigate to="edit" replace/>}
            />
            <Route
              path={':orgId/edit'}
              element={<AddEditOrganisation/>}
            />
            <Route
              path={'/deleted'}
              element={<DeletedOrganisations/>}
            />
          </Routes>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...organisationsActions,
};

export default connect(null, mapDispatchToProps)(Organisations);
