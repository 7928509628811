import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import Input from "../input";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const DatePicker = React.forwardRef(({ value, onChange, dateFormat = "do MMMM yyyy", onFocus, onBlur }, ref) => {
  return (
    <ReactDatePicker
      ref={ref}
      selected={value ? value.toDate() : null}
      onChange={(change) => onChange(moment(change))}
      onFocus={onFocus}
      onBlur={onBlur}
      className="input__input"
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      dateFormat={dateFormat}
    />
  );
});

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  dateFormat: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Input(DatePicker);