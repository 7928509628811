import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import InlineError from "./inline-error";
import uuid from "node-uuid";
import "./input.scss";

const Input = (InputComponent) => {
  const WrappedInput =({ autoFocus, onFocus, onBlur, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);
    const isMounted = useRef(true);
    const timeoutRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
      isMounted.current = true;
      if (autoFocus) {
        focus();
      }
      return () => {
        isMounted.current = false;
      };
    }, [autoFocus]);

    const focus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleFocus = (e) => {
      clearTimeout(timeoutRef.current);
      setIsFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleBlur = (e) => {
      timeoutRef.current = setTimeout(() => {
        if (isMounted.current) {
          setIsFocused(false);
        }
      }, 20);
      if (onBlur) {
        onBlur(e);
      }
    };

    const {
      disabled,
      label,
      labelHelper,
      value = '',
      error,
      shaded,
      optional,
      prefix,
    } = props;

    const id = props.id ?? uuid.v4();

    const isFilled =
    (value !== undefined && value !== null && typeof value !== "string") ||
    (typeof value === "string" && value.length);
    const isInvalid = error && error.length;

    const inputClass = classNames("input", {
      "input--disabled": disabled,
      "input--focused": isFocused,
      "input--filled": isFilled,
      "input--error": isInvalid,
      "input--shaded": shaded,
      "input--prefixed": prefix,
    });

    return (
      <div className={inputClass}>
        <label className="input__label" htmlFor={id}>
          {label} {labelHelper ? <small>{labelHelper}</small> : null}
          {optional ? <small>(optional)</small> : null}
        </label>
        {prefix ? <span className="input__prefix">{prefix}</span> : null}
         <InputComponent
          {...props}
          ref={inputRef}
          value={value}
          isFocused={isFocused}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {isInvalid ? <InlineError message={error} /> : null}
      </div>
    );
  };

  return WrappedInput;
};

export default Input;