import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Navigate, useNavigate } from "react-router-dom";
import {
  isAuthenticated,
  getAuth,
  authActions,
  getIsAdmin,
} from "../../core/auth";
import {
  getNotifications,
  notificationsActions,
} from "../../core/notifications";
import { NotificationStack } from "react-notification";
import Main from "./main";
import Nav from "../components/nav/container";
import "./index.scss";

function App (props) {
  const navigate = useNavigate();

  useEffect(() => {
    const {
      auth,
      isAdmin,
      signOut,
      signInError,
    } = props;
    if (auth.initialised && !isAdmin) {
      signOut({ preserveError: true });
      signInError({ message: "You do not have an account on this site" });
      navigate('/sign-in');
    }
  }, [props, navigate]);

  const {
    authenticated,
    auth,
    allNotifications,
    removeNotification,
  } = props;

  if (!authenticated) {
    return <Navigate to="/sign-in" replace/>;
  }

  const notifications = allNotifications.map(notification => {
    notification.onClick = removeNotification.bind(this, notification.key);
    return notification;
  });

  return (
    <div className="book-creator-mission-control" data-id={auth.id}>
      <Nav auth={auth} />
      <Main />
      <NotificationStack
        notifications={notifications.toArray()}
        onDismiss={notification => {}}
      />
    </div>
  );
}

const mapStateToProps = createSelector(
  isAuthenticated,
  getAuth,
  getNotifications,
  getIsAdmin,
  (authenticated, auth, allNotifications, isAdmin) => ({
    authenticated,
    auth,
    allNotifications,
    isAdmin,
  })
);

const mapDispatchToProps = {
  ...notificationsActions,
  ...authActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
