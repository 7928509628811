import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { Routes, Route,  useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, authActions, getAuth } from "../../../core/auth";
import BookCreatorLogoColour from "../../../images/book-creator-logo-colour.png";
import SignIn from "../../components/sign-in/sign-in";
import SignInEmail from "../../components/sign-in/sign-in-with-email";
import Register from "../../components/sign-in/register";
import ForgottenPassword from "../../components/sign-in/forgotten-password";
import "./index.scss";

function SignInPage(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [redirectTo, setRedirectTo] = useState("/");
  const { authenticated } = props;
  
  useEffect(() => {
    if (location.state && location.state.from) {
      setRedirectTo(`${location.state.from.pathname}${location.state.from.search}`)
    }
  }, [location]);
  
  
  useEffect(() => {
    if (authenticated) {
      navigate(redirectTo);
    }
  }, [authenticated, navigate, redirectTo]);


  return (
    <div className="sign-in">
      <div className="sign-in__content">
        <h1 className="sign-in__logo">
          <img src={BookCreatorLogoColour} alt="Book Creator" />
        </h1>
        <Routes>
          <Route path="/" element={<SignIn {...props} />} />
          <Route path="/register" element={<Register {...props} />} />
          <Route path="/register/:email" element={<Register {...props} />} />
          <Route path="/email" element={<SignInEmail {...props} />} />
          <Route path="/email/:email" element={<SignInEmail {...props} />} />
          <Route path="/forgotten-password" element={<ForgottenPassword {...props} />} />
          <Route path="/forgotten-password/:email" element={<ForgottenPassword {...props} />} />
        </Routes>
      </div>
    </div>
  );
}

const mapStateToProps = createSelector(
  isAuthenticated,
  getAuth,
  (authenticated, auth) => ({
    authenticated,
    auth,
  })
);

const mapDispatchToProps = {
  ...authActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
