import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "../../../../images/svg/search.svg";
import SvgIcon from "../../ui/svg-icon";
import "./index.scss";
import { flushSync } from "react-dom";

export default function SearchField(props) {
  const { placeholder, search, onSubmit } = props;

  const [textInput, setTextInput] = useState(search || "");

  useEffect(() => {
    if (search) {
      setTextInput(search);
    }
  }, [search]);

  const handleSubmit = e => {
    flushSync(() => {
      onSubmit(textInput);
    });
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} className="search-field">
      <input
        type="text"
        value={textInput}
        placeholder={placeholder}
        className="search-field__input"
        onChange={e => {
          setTextInput(e.target.value);
        }}
      />
      <button type="submit" className="search-field__submit">
        <SvgIcon icon={SearchIcon} name="search" />
      </button>
    </form>
  );
}

SearchField.propTypes = {
  search: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
