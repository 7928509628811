import React, { Fragment, useState } from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet"
import Header, { HeaderActions } from "../../../header";
import PageControl from "../../../ui/page-control";
import ChevronIcon from "../../../../../images/svg/chevron.svg";
import Content from "../../../content";
import Results from "../search/results";
import BlockByUrl from "./block-by-url";
import CheckBox from "../../../forms/checkbox";
import TextOnly from "./text-only";

function BlockedImages(props) {
  const { blockedURLs, userId } = props;
  const location = useLocation();

  const [showBlockByUrl, setShowBlockByUrl] = useState(false);
  const [showImages, setShowImages] = useState(true);

  const params = new URLSearchParams(location.search);
  const searchTerm = params.get("search");
  const backUrl = searchTerm
    ? `/image-search/google?search=${searchTerm}`
    : `/image-search/google`;

  return (
    <Fragment>
      <Helmet title="Image Search" />
      <Header title="Image Search">
        <HeaderActions>
          <PageControl
            link={backUrl}
            text="Search"
            icon={ChevronIcon}
            iconName="back"
          />
        </HeaderActions>
        <HeaderActions>
          <PageControl
            onClick={(evt) => setShowBlockByUrl(true)}
            text="Block by URL"
          />
        </HeaderActions>
      </Header>
      <Content>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 200,
            margin: "0 auto 20px",
            background: "#fff",
            padding: 10,
          }}
        >
          <CheckBox
            checked={showImages}
            onClick={() => setShowImages(!showImages)}
          />
          &nbsp;&nbsp;Show images
        </div>
        {blockedURLs.length === 0 ? (
          <p style={{ textAlign: "center" }}>No images have been blocked</p>
        ) : null}
        {showImages ? (
          <Results
            results={blockedURLs.map(({ link }) => {
              return {
                link,
                image: {
                  thumbnailLink: link,
                },
              };
            })}
            blockedURLs={blockedURLs}
            userId={userId}
          />
        ) : (
          <TextOnly blockedURLs={blockedURLs} />
        )}
      </Content>
      {showBlockByUrl ? (
        <BlockByUrl onClose={() => setShowBlockByUrl(false)} userId={userId} />
      ) : null}
    </Fragment>
  );
}

export default BlockedImages;
