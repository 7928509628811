import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Loader from "../ui/loader";
import "./form-button.scss";

function FormButton(props) {
  const [clicks, setClicks] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, []);

  useEffect(() => {
    if(clicks){
      setTimeout(() => {
        let updateClicks = clicks.slice();
        updateClicks.splice(0, 1);
        if (isMounted) {
          setClicks(updateClicks);
        }
      }, 1000);
    }
  }, [clicks, isMounted]);

  const handleClick = (e) => {
    if (props.onClick) {
      props.onClick();
    }
    const { layerX, layerY } = e.nativeEvent;
    let updateClicks = clicks.slice();
    updateClicks.push({ x: layerX, y: layerY });
    setClicks(updateClicks);
  }

  const { type, text, href, flat, loading, disabled, minWidth } = props;

  const waves = clicks.map((click, i) => {
    return (
      <div
        className="form-button__wave"
        key={i}
        style={{ margin: `${click.y}px 0 0 ${click.x}px` }}
      />
    );
  });

  const buttonClass = classNames("form-button", {
    "form-button--flat": flat,
  });

  const buttonStyle = {
    minWidth,
  };

  if (href) {
    return (
      <Link to={href} className={buttonClass}>
        {text}
      </Link>
    );
  } else {
    return (
      <button
        type={type ? type : "button"}
        className={buttonClass}
        style={buttonStyle}
        disabled={loading || disabled}
        onClick={handleClick}
      >
        {loading ? <Loader tiny /> : text}
        {waves}
      </button>
    );
  }
}

export default FormButton;
