import React, { useEffect, useState } from "react";
import Button from "../../../../../button";
import SvgIcon from "../../../../../ui/svg-icon";
import DeleteIcon from "../../../../../../../images/svg/delete.svg";
import EditIcon from "../../../../../../../images/svg/edit.svg";
import classNames from "classnames";
import { isValidDomain } from "../../../../../../../utils/validators";
import TestCase from "./test-case";
import ReadView from "../../item";
import { OrgDomain, OrgDomainTestCase, OrgUserType } from "../..";
import "./index.scss";
import { useValidatedTestCases } from "../../validate-test-cases-hooks";
import { isSameDomain } from "../../../helpers.js";
interface DomainEditorItemProps {
  domain?: string;
  domains: OrgDomain[];
  id?: string;
  local?: string;
  testCases?: OrgDomainTestCase[];
  type?: OrgUserType;
  editDisabled?: boolean;
  editing?: boolean;
  orgEditMode?: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
  onSave: ({ domain }: { domain: OrgDomain; oldDomain?: OrgDomain }) => void;
  setEditing?: (domain: OrgDomain) => void;
}

export default function DomainEditorItem(props: DomainEditorItemProps) {
  const {
    domains,
    editDisabled,
    editing,
    orgEditMode,
    onCancel,
    onDelete,
    onSave,
    setEditing,
  } = props;

  const [addingTestCase, setAddingTestCase] = useState(false);
  const [editingTestCase, setEditingTestCase] = useState(null);
  const [domain, setDomain] = useState(props.domain || "");
  const [local, setLocal] = useState(props.local || "");
  const [testCases, setTestCases] = useState(props.testCases || []);
  const [checkTestCase, setCheckTestCase] = useState(false);
  const [type, setType] = useState<OrgUserType>(
    props.type || OrgUserType.STUDENT
  );
  const [domainBlurred, setDomainBlurred] = useState(false);
  const [localBlurred, setLocalBlurred] = useState(false);

  // filter out the current domain from the list
  const domainExists = domains
    .filter(
      d =>
        d.domain !== props.domain ||
        (d.domain === props.domain && d.local !== props.local)
    )
    .find(d => isSameDomain(d, { domain, local, type }));

  const isNew = !props.domain;

  const domainIsValid = (() => {
    return !!(domain && isValidDomain(domain) && !domainExists);
  })();

  const regexIsValid = (() => {
    if (local) {
      try {
        new RegExp(local);
      } catch (e) {
        return false;
      }
    }
    return true;
  })();

  const isValid = domainIsValid && regexIsValid;

  const { validatedTestCases, isLoading } = useValidatedTestCases(
    {
      domain,
      local,
      type,
      testCases,
    },
    domains.filter(d => d.domain !== domain && d.domain !== props.domain),
    checkTestCase
  );

  useEffect(() => {
    if (checkTestCase && !isLoading) {
      setCheckTestCase(false);
    }
  }, [checkTestCase, isLoading]);

  useEffect(() => {
    if (isValid && testCases.length) {
      setCheckTestCase(true);
    }
  }, [isValid, testCases, domain, local, type]);

  const handleSave = () => {
    onSave({
      domain: { id: props.id, domain, local, testCases, type },
      oldDomain: {
        id: props.id,
        domain: props.domain,
        local: props.local,
        type: props.type,
      },
    });
  };

  const handleSubmit = (evt?: React.FormEvent) => {
    evt?.preventDefault();
    if (isValid) {
      handleSave();
    }
  };

  const handleCancel = () => {
    setDomain(props.domain || "");
    setLocal(props.local || "");
    setTestCases(props.testCases || []);
    setType(props.type || OrgUserType.STUDENT);
    onCancel?.();
  };

  const handleInputKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === "Enter") {
      handleSubmit();
    }
  };

  const addTestCase = (testCase: OrgDomainTestCase) => {
    setTestCases([...testCases, testCase]);
    setAddingTestCase(false);
  };

  const editTestCase = (testCase: OrgDomainTestCase) => {
    // find the matching item in testCases
    const updatedTestCases = testCases.map(item => {
      if (item.email === editingTestCase) {
        return testCase;
      }
      return item;
    });

    setTestCases(updatedTestCases);
    setEditingTestCase(null);
  };

  const deleteTestCase = (email: string) => {
    setTestCases(testCases.filter(testCase => testCase.email !== email));
  };

  const onTypeChange = evt => {
    const type = evt.target.value as OrgUserType;
    setType(type);
    if (type === OrgUserType.ALL) {
      setLocal("");
    }
  };

  const content = (
    <>
      <div className="domain-editor-item__row">
        <label
          htmlFor="domain-editor-item__value--domain"
          className="domain-editor-item__label"
          style={{
            color: !domainIsValid && domainBlurred ? "red" : null,
          }}>
          Domain:
        </label>
        <input
          type="text"
          value={domain}
          id="domain-editor-item__value--domain"
          onChange={evt => setDomain(evt.target.value.toLowerCase())}
          onBlur={() => setDomainBlurred(true)}
          className="domain-editor-item__input"
          onKeyDown={handleInputKeyDown}
          // biome-ignore lint/a11y/noAutofocus: Internal use only
          autoFocus
          style={{
            borderColor: !domainIsValid && domainBlurred ? "red" : null,
          }}
        />
        {domainBlurred && !domainIsValid ? (
          <span className="domain-editor-item__error">
            {domainExists ? "Domain exists" : "Invalid domain"}
          </span>
        ) : null}
      </div>
      <div className="domain-editor-item__row">
        <label
          htmlFor="domain-editor-item__value--regex"
          className="domain-editor-item__label"
          style={{
            color: regexIsValid && localBlurred ? "inherit" : null,
          }}>
          Local:
        </label>
        <input
          type="text"
          value={local}
          disabled={type === "ALL"}
          id="domain-editor-item__value--regex"
          onChange={evt => setLocal(evt.target.value.trim())}
          onBlur={() => setLocalBlurred(true)}
          onKeyDown={handleInputKeyDown}
          data-invalid={!regexIsValid && !localBlurred}
          className="domain-editor-item__input domain-editor-item__input--short"
          style={{
            borderColor: !regexIsValid && localBlurred ? "red" : null,
          }}
        />
        {localBlurred && !regexIsValid ? (
          <span className="domain-editor-item__error">Invalid regex</span>
        ) : null}
      </div>
      <div className="domain-editor-item__row">
        <label className="domain-editor-item__label">Type:</label>
        <select
          value={type}
          onChange={onTypeChange}
          className="domain-editor-item__select">
          <option value={OrgUserType.STUDENT}>Student</option>
          <option value={OrgUserType.TEACHER}>Teacher</option>
          <option value={OrgUserType.ALL}>All</option>
        </select>
      </div>
      <div className="domain-editor-item__row">
        <label className="domain-editor-item__label">Test cases:</label>
        <div className="domain-editor-item__value">
          {testCases.map((testCase, _) => (
            <div className="domain-editor-item__test" key={testCase.email}>
              <TestCase
                {...testCase}
                key={testCase.email}
                domain={domain}
                domainType={type}
                local={local}
                existingCaseEmails={testCases
                  .filter(tc => tc.email !== testCase.email)
                  .map(tc => tc.email)}
                editing={editingTestCase === testCase.email}
                onEdit={editTestCase}
                onCancel={() => setEditingTestCase(null)}
                pass={
                  validatedTestCases?.find(
                    tc =>
                      tc.email === testCase.email &&
                      tc.expectedType === testCase.expectedType
                  )?.valid
                }
              />
              {!editingTestCase && !addingTestCase ? (
                <>
                  <button
                    className="domain-editor-item__test-delete"
                    onClick={() => deleteTestCase(testCase.email)}
                    type="button">
                    <SvgIcon icon={DeleteIcon} />
                  </button>
                  <button
                    className="domain-editor-item__test-edit"
                    onClick={() => setEditingTestCase(testCase.email)}
                    type="button">
                    <SvgIcon icon={EditIcon} />
                  </button>
                </>
              ) : null}
            </div>
          ))}
          {addingTestCase ? (
            <TestCase
              existingCaseEmails={testCases.map(tc => tc.email)}
              onSave={addTestCase}
              onCancel={() => setAddingTestCase(false)}
              domain={domain}
              domainType={type}
              local={local}
            />
          ) : !editingTestCase ? (
            <Button onClick={() => setAddingTestCase(true)} tiny>
              Add test case
            </Button>
          ) : null}
        </div>
      </div>
      <div className="domain-editor-item__actions">
        <Button onClick={handleCancel} small text>
          Cancel
        </Button>
        <Button
          small
          type="submit"
          disabled={!isValid || editingTestCase || addingTestCase}
          onClick={handleSubmit}>
          {isNew ? "Add" : orgEditMode ? "Done" : "Save"}
        </Button>
      </div>
    </>
  );

  const className = classNames("domain-editor-item");

  return (
    <div className={className}>
      {editing ? (
        <div className="domain-editor-item__edit">{content}</div>
      ) : (
        <div className="domain-editor-item__read">
          <ReadView
            domains={domains}
            domain={domain}
            editDisabled={editDisabled}
            type={type}
            testCases={testCases}
            local={local}
            onEdit={() => setEditing({ domain, local, type })}
            onDelete={onDelete}
          />
        </div>
      )}
    </div>
  );
}
