import React from "react";
import { Helmet } from "react-helmet"
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { notificationsActions } from "../../../core/notifications";
import Header from "../../components/header";
import Content from "../../components/content";
import SearchField from "../../components/forms/search-field";
import Analytics from "../../components/analytics";
import "./index.scss";

function AnalyticsPage(props) {
  const navigate = useNavigate();
  const { domain } = useParams();

  const handleSearch = (query) => {
    let trimmedQuery = query.trim();
    trimmedQuery = trimmedQuery.replace("@", "");
    navigate(`/analytics/${trimmedQuery}`);
  };

  return (
    <div>
      <Helmet title="Analytics" />
      <Header title="Analytics"></Header>
      <Content>
        <SearchField
          onSubmit={handleSearch}
          search={domain}
          placeholder="Search by domain"
        />
        {domain && domain.length ? (
          <Analytics domain={domain} key={domain} />
        ) : null}
      </Content>
    </div>
  );
}

const mapDispatchToProps = {
  ...notificationsActions,
};

export default connect(null, mapDispatchToProps)(AnalyticsPage);
