import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import Stats from "../stats";
import Header, { HeaderActions } from "../../header";
import HeaderButton from "../../button/header";
import html2pdf from "html2pdf.js";
import Settings from "../settings";
import PDFHeader from "./header";
import Charts from "../charts";
import Loader from "../../ui/loader";
import PageControl from "../../ui/page-control";
import "./index.scss";

function AnalyticsPrintView(props) {
  const { domain, hiddenCharts, onClose, onSettingChange, auth } = props;

  const containerRef = useRef();
  const [printing, setPrinting] = useState(false);

  const handlePrintClick = e => {
    setPrinting(true);
    const opts = {
      filename: `${domain}-analytics.pdf`,
      pagebreak: {
        mode: "avoid-all",
      },
      margin: [15, 0, 0, 0],
    };
    var worker = html2pdf().set(opts).from(containerRef.current);
    worker.save().then(() => {
      setPrinting(false);
    });
  };

  const className = classNames("print-preview__main", {
    printing: printing,
  });

  return createPortal(
    <div className="print-preview">
      <Header title="Analytics" fixed>
        <HeaderActions>
          <PageControl text="Close" onClick={onClose} />
        </HeaderActions>
        <HeaderActions right>
          <Settings {...props} onChange={onSettingChange} />
          <HeaderButton onClick={handlePrintClick}>Save</HeaderButton>
        </HeaderActions>
      </Header>
      <div className="print-preview__container">
        {printing ? (
          <div className="print-preview__printing">
            <span>Saving</span>
            <Loader />
          </div>
        ) : null}
        <div ref={containerRef}>
          <div className={className}>
            <PDFHeader />
            <Stats domain={domain} hiddenCharts={hiddenCharts} auth={auth}/>
            <Charts
              auth={auth}
              domain={domain}
              hiddenCharts={hiddenCharts}
              chartHeight={320}
              chartWidth={680}
              font="Arial"
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementsByTagName("body")[0]
  );
}

export default AnalyticsPrintView;
