import React, { useEffect } from "react";
import Input from "./input";
import uuid from "node-uuid";

const TextArea = React.forwardRef((props, ref) => {
  const {
    onFocus,
    onBlur,
    onChange,
    onPaste,
    value,
    name,
    maxLength,
    height,
    autoFocus,
    disabled,
    innerRef,
  } = props;

  const id = props.id ?? uuid.v4();

  useEffect(() => {
    if (autoFocus) {
      document.getElementById(id).focus();
    }
  } , [autoFocus, id]);

  
  const style = {
    height,
  };

  const opts = {
    onFocus,
    onBlur,
    onChange,
    onPaste,
    id: id,
    style,
  };

  return (
    <textarea
    {...opts}
    className="input__textarea"
    ref={innerRef || ref}
    value={value}
    name={name}
    maxLength={maxLength}
    onChange={e => onChange(e.target.value)}
    disabled={disabled}
  />
);
});

export default Input(TextArea);