import { sha256 } from "js-sha256";
import { Buffer } from "buffer";

export function replaceAll(string, search, replacement) {
  return string.split(search).join(replacement);
}

export const getDomainKeys = domains => {
  return domains.map(domain => {
    return encodeDomain(domain);
  });
};

export const encodeDomain = domain => {
  const data = Buffer.from(domain, "utf8");

  // URL safe base64 encoding
  let encoded = data.toString("base64");
  encoded = encoded.replace(/=+$/, "");
  encoded = encoded.replace(/\//g, "_");
  encoded = encoded.replace(/\+/g, "-");

  return encoded;
};

export const decodeDomain = domainKey => {
  if (!domainKey || typeof domainKey !== "string") return null;

  // Base64 URL safe decode
  domainKey = domainKey.replace(/_/g, "/");
  domainKey = domainKey.replace(/-/g, "+");

  return Buffer.from(domainKey, "base64").toString("utf8");
};

export const linkToHash = link => sha256(link).toString(16).toLowerCase();

export const isTimestamp = string => new Date(string).getTime() > 0;
