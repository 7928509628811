export function isValidEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isValidUrl(url) {
  let urlRegEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
  return urlRegEx.test(url.toLowerCase());
}

export function isValidXeroUrl(url) {
  return (
    isValidUrl(url) &&
    url.indexOf("https://go.xero.com/") === 0 &&
    url.length > 20
  );
}

export function getEmailError(email, lang = "en") {
  return !email?.length
    ? "Please enter your email address"
    : !isValidEmail(email)
    ? "Invalid email address"
    : null;
}

export function getPasswordError(password, lang = "en") {
  return password
    ? password.length > 5
      ? null
      : "Password must be at least 6 characters"
    : "Please enter a password";
}

export function getFullNameError(fullName, lang = "en") {
  return !fullName || !fullName.length ? "Please enter your name" : null;
}

export const isTimestamp = string => new Date(string).getTime() > 0;

export const isValidDomain = domain => {
  if (!domain) {
    return false;
  }
  return domain.match(/^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i);
};
