import React, { useState, useCallback, useRef, useEffect } from "react";
import { firebaseDb } from "../../../../../core/firebase";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { notificationsActions } from "../../../../../core/notifications";
import { getUserId } from "../../../../../core/auth";
import DropDown from "../../../forms/dropdown";
import Loader from "../../../ui/loader";
import InlineButton from "../../../ui/inline-button";
import SvgIcon from "../../../ui/svg-icon";
import TickIcon from "../../../../../images/svg/tick.svg";
import "./index.scss";

const plans = [
  {
    bookQuota: 40,
    libraryQuota: 1,
    name: "Free plan",
  },
  {
    bookQuota: 1000,
    libraryQuota: "UNLIMITED",
  },
  {
    bookQuota: 60,
    libraryQuota: 3,
    hidden: true,
  },
];

const getPlanDisplayName = (plan) => {
  if (plan.name) {
    return plan.name;
  } else if (plan.libraryQuota === "UNLIMITED") {
    return `${plan.bookQuota} book plan`;
  } else {
    return `${plan.bookQuota * plan.libraryQuota} book plan`;
  }
};

const Plan = (props) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [previousPlan, setPreviousPlan] = useState(null);
  const [succeeded, setSucceded] = useState(false);
  const [successTimeout, setSuccessTimeout] = useState(null);
  const isMounted = useRef(true);

  const { currentUserId,addErrorMessage,uid, bookQuota = 40, libraryQuota = 1 } = props;

  useEffect(() => {
    const getPlanIndex = (bookQuota) => {
      return plans.map((plan) => plan.bookQuota).indexOf(bookQuota);
    };
    isMounted.current = true;
    const currentPlan = getPlanIndex(bookQuota);
    setSelectedPlan(currentPlan);
    setPreviousPlan(currentPlan);

    return () => {
      isMounted.current = false;
    };
  }, [bookQuota]);


  const handlePlanChange = useCallback((dropdownValue) => {
    clearTimeout(successTimeout);
    setSaving(true);
    setEditing(false);
    setSelectedPlan(dropdownValue.value); 

    const plan = plans[dropdownValue.value];
    const oldPlan = plans[previousPlan];
    const updates = {};
    const oldPlanString = `${oldPlan.libraryQuota}x${oldPlan.bookQuota}`;
    const newPlanString = `${plan.libraryQuota}x${plan.bookQuota}`;
    let historyEntry = {
      date: Date.now(),
      from: oldPlanString,
      to: newPlanString,
      userId: currentUserId,
    };
    updates[`/users/${uid}/bookQuota`] = plan.bookQuota;
    updates[`/users/${uid}/libraryQuota`] = plan.libraryQuota;
    updates[`/users/${uid}/collaborationTrialStarted`] = null;
    updates[`/users/${uid}/collaborationTrialEnded`] = null;
    updates[`/users/${uid}/collaborationTrialEnds`] = null;
    updates[
      `/missionControlPlanChangeHistory/${uid}/${historyEntry.date}`
    ] = historyEntry;
    firebaseDb
      .ref(`/`)
      .update(updates)
      .then(() => {
        setSaving(false);
        setSucceded(true);
        setPreviousPlan(dropdownValue.value);

        const timeout = setTimeout(() => {
          if (isMounted.current) {
            setSucceded(false);
          }
        }, 3000);
        setSuccessTimeout(timeout);
      })
      .catch((error) => {
        setSaving(false);
        console.error(error);
        addErrorMessage(error.message);
      });
  }, [addErrorMessage, currentUserId, uid, previousPlan, successTimeout]);



  const handleEditClick = useCallback(() => {
    setEditing(true);
  }, []);

  let currentPlanData = plans[selectedPlan] || {};
    if (!currentPlanData.bookQuota) {
      currentPlanData.bookQuota = bookQuota;
      currentPlanData.libraryQuota = libraryQuota;
    }

  return (
    <div className="result-plan">
      {editing ? (
        <>
          <DropDown
              value={{
                value: selectedPlan,
                label: getPlanDisplayName(currentPlanData),
              }}
            width={150}
            open
            onChange={handlePlanChange}
            options={plans
              .filter((plan) => !plan.hidden)
              .map((plan, i) => ({
                value: i,
                label: getPlanDisplayName(plan),
              }))}
          />
          <InlineButton onClick={() => setEditing(false)}>Cancel</InlineButton>
        </>
      ) : (
        <span className="result-plan__read">
          {getPlanDisplayName(currentPlanData)}
        </span>
      )}
      {saving ? <Loader tiny /> : null}
      {succeeded && !saving ? <SvgIcon icon={TickIcon} name="tick" /> : null}
      {!editing && !saving && !succeeded ? (
        <InlineButton onClick={handleEditClick}>Change</InlineButton>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  ...notificationsActions,
};

const mapStateToProps = createSelector(getUserId, (currentUserId) => ({
  currentUserId,
}));

export default connect(mapStateToProps, mapDispatchToProps)(Plan);