import React, { useRef, useEffect, useState } from "react";
import { SEARCH_API_DOMAIN } from "../../../../../config";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "use-http";
import SearchField from "../../../forms/search-field";
import Loader from "../../../ui/loader";
import Results from "./results";
import Message from "../../../ui/message";
import Button from "../../../button";
import "./index.scss";

function GoogleImageSearch(props) {
  const { blockedURLs, userId } = props;

  const [results, setResults] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [nextMatch, setNextMatch] = useState(null);
  const locationSearchRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const { get, response, error } = useFetch(
    `${SEARCH_API_DOMAIN}/google/images`,
    {
      credentials: "include",
    }
  );

  const handleSearch = async (searchTerm) => {
    navigate(`/image-search/google?search=${searchTerm}`);
  };

  const loadMore = async () => {
    setNextMatch(null);
    const result = await get(nextMatch);
    if (response.ok) {
      setResults([...results, ...result]);
      const link = response.headers.get("link");
      const nextMatch = /<(?<next>.+?)>;/.exec(link);
      if (nextMatch && nextMatch.groups.next) {
        setNextMatch(nextMatch.groups.next);
      }
    }
  };

  useEffect(() => {
    const doSearch = async () => {
      const params = new URLSearchParams(location.search);
      const searchTerm = params.get("search");
      setSearching(true);
      const lang = "en";
      const result = await get(
        encodeURI(
          `${searchTerm.toLowerCase()}?userInterfaceLanguage=${lang}&fields=mime,title,image.width,image.height,image.thumbnailWidth,image.thumbnailHeight,image.contextLink`
        )
      );
      setSearching(false);
      setSearchError(false);
      if (response.ok) {
        const link = response.headers.get("link");
        const nextMatch = /<(?<next>.+?)>;/.exec(link);
        if (nextMatch && nextMatch.groups.next) {
          setNextMatch(nextMatch.groups.next);
        }
        setResults(result);
      } else {
        setSearchError(true);
      }
    };

    if (
      location.search &&
      location.search !== locationSearchRef.current
    ) {
      doSearch();
      locationSearchRef.current = location.search;
    }
  }, [
    location.search,
    locationSearchRef,
    get,
    response.ok,
    response.status,
    response.headers,
  ]);

  const params = new URLSearchParams(location.search);
  const searchedTerm = params.get("search");

  return (
    <div>
      <SearchField
        onSubmit={handleSearch}
        placeholder="Search for images"
        search={searchedTerm}
      />
      {error || searchError ? (
        <Message
          type="error"
          style={{ maxWidth: 500, margin: "0 auto", textAlign: "center" }}
        >
          {(error && error.message) ||
            "Something went wrong searching for the images"}
        </Message>
      ) : null}
      {searching ? <Loader /> : null}
      {results && results.length === 0 ? (
        <p style={{ textAlign: "center" }}>No results found '{searchedTerm}'</p>
      ) : null}
      {results && results.length > 0 ? (
        <Results
          results={results}
          blockedURLs={blockedURLs}
          obscureblocked
          userId={userId}
        />
      ) : null}
      {nextMatch ? (
        <Button
          onClick={loadMore}
          type="primary"
          className="image-search__more"
        >
          Load More
        </Button>
      ) : null}
    </div>
  );
}

export default GoogleImageSearch;
