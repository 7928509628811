import { useEffect, useState } from "react";
import Header from "../../components/header";
import Content from "../../components/content";
import { firebaseFirestore as fs } from "../../../core/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

import "./index.scss";

function PageTemplates(props) {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const getTemplates = async () => {
      const q = query(
        collection(fs, "templates"),
        where("pageTemplatesOnly", "!=", null),
      );
      const querySnapshot = await getDocs(q);
      const templates = [];
      querySnapshot.forEach(doc => {
        templates.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setDocs(templates);
    };
    getTemplates();
  }, []);

  const removeTemplate = async (id, value) => {
    console.log({ id, value });
    fs.doc(`/templates/${id}`).update({
      pageTemplatesOnly: value,
    });
    setDocs(
      docs.map(doc =>
        doc.id === id ? { ...doc, pageTemplatesOnly: value } : doc,
      ),
    );
  };

  return (
    <div className="page-templates">
      <Header title="Page Templates" />
      <Content>
        {docs
          .sort((a, b) => a.title.localeCompare(b.title))
          .sort((a, b) => b.pageTemplatesOnly - a.pageTemplatesOnly)
          .map(doc => {
            return (
              <div key={doc.id} className="page-template">
                <span className="page-template__title">{doc.title}</span>
                <span className="page-template__id">{doc.id}</span>
                <label className="page-template__templates-only">
                  <input
                    type="checkbox"
                    checked={doc.pageTemplatesOnly}
                    onChange={() =>
                      removeTemplate(doc.id, !doc.pageTemplatesOnly)
                    }
                  />
                  Page templates only
                </label>
              </div>
            );
          })}
      </Content>
    </div>
  );
}

export default PageTemplates;
