import { Record, fromJS } from "immutable";

import {
  SEARCH_USERS_ATTEMPT,
  SEARCH_USERS_RESULT,
  SEARCH_USERS_MULTIPLE_RESULT,
  SEARCH_USERS_NOT_FOUND,
  SET_SHOW_DELETE_USER_OVERLAY,
  SET_SHOW_IMPERSONATE_USER_OVERLAY,
  SET_SIGNING_IN_AS_USER,
  SET_USER_TO_DELETE,
  CLEAR_USER_TO_DELETE,
  USER_DELETED,
  DELETE_USER_ATTEMPT,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RECEIVE_ADDITIONAL_USER_INFO,
  DOMAIN_SEARCH_ATTEMPT,
  DOMAIN_SEARCH_RESULT,
  DOMAIN_SEARCH_NO_RESULT,
  RESET_USER_SEARCH,
  RESET_USER_STATE,
  UPDATE_USER_VALUE,
} from "./action-types";

import { SIGN_OUT_SUCCESS } from "../auth";

export const UsersState = new Record({
  searching: false,
  searchTerm: null,
  searchResult: null,
  searchResultMultiple: null,
  showDeleteUserModal: false,
  userToImpersonate: null,
  userToDelete: null,
  deletingUser: false,
  searchingDomains: false,
  domainSearchResults: null,
  domainSearchTerm: null,
  domainSearchComplete: false,
});

export function usersReducer(state = new UsersState(), { payload, type }) {
  switch (type) {
    case SEARCH_USERS_ATTEMPT:
      return state.merge({
        searching: true,
      });
    case SEARCH_USERS_RESULT:
      const { result, searchTerm } = payload;
      return state.merge({
        searchResult: fromJS(result),
        searchResultMultiple: null,
        searchTerm,
      });
    case SEARCH_USERS_MULTIPLE_RESULT:
      return state.merge({
        searching: false,
        searchResultMultiple: fromJS(payload.result),
        searchTerm: payload.searchTerm,
      });
    case SEARCH_USERS_NOT_FOUND:
      return state.merge({
        searchResult: null,
        searchResultMultiple: null,
        searching: false,
        searchTerm: payload.searchTerm,
      });
    case RESET_USER_SEARCH:
      return state.merge({
        searchResult: null,
        searchResultMultiple: null,
        searching: false,
        searchTerm: null,
      });
    case RESET_USER_STATE:
      return new UsersState();
    case RECEIVE_ADDITIONAL_USER_INFO:
      return state.merge({
        searching: false,
        searchResult: fromJS(payload),
      });
    case SET_SHOW_DELETE_USER_OVERLAY:
      return state.merge({ showDeleteUserModal: payload });
    case SET_SHOW_IMPERSONATE_USER_OVERLAY:
      return state.merge({ userToImpersonate: payload });
    case SET_SIGNING_IN_AS_USER:
      if (state.userToImpersonate) {
        return state.merge({
          userToImpersonate: state.userToImpersonate.set("loading", payload),
        });
      } else {
        return state;
      }
    case SET_USER_TO_DELETE:
      return state.merge({
        userToDelete: payload,
      });
    case CLEAR_USER_TO_DELETE:
      return state.merge({
        userToDelete: null,
      });
    case USER_DELETED:
      return state.merge({
        userToDelete: null,
        showDeleteUserModal: false,
        searchResult: null,
        searchTerm: null,
      });
    case DELETE_USER_ATTEMPT:
      return state.merge({
        deletingUser: true,
      });
    case DELETE_USER_SUCCESS:
    case DELETE_USER_ERROR:
      return state.merge({
        deletingUser: false,
      });
    case DOMAIN_SEARCH_ATTEMPT:
      return state.merge({
        searchingDomains: true,
        searching: false,
        domainSearchResults: null,
        searchTerm: null,
        searchResult: null,
        domainSearchComplete: false,
      });
    case DOMAIN_SEARCH_RESULT:
      return state.merge({
        searchingDomains: false,
        domainSearchResults: state.domainSearchResults
          ? [...state.domainSearchResults, ...payload.result]
          : payload.result,
        domainSearchTerm: payload.searchTerm,
        domainSearchComplete: payload.domainSearchComplete,
      });
    case DOMAIN_SEARCH_NO_RESULT:
      return state.merge({
        searchingDomains: null,
        searching: false,
        searchTerm: payload.searchTerm,
        domainSearchComplete: true,
      });
    case UPDATE_USER_VALUE:
      const { location, value } = payload;
      return state.merge({
        searchResult: state.searchResult.set(location, value),
      });
    case SIGN_OUT_SUCCESS:
      return new UsersState();
    default:
      return state;
  }
}
