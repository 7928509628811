import React, { useState } from "react";
import { Helmet } from "react-helmet"
import Header from "../../components/header";
import Content from "../../components/content";
import DataTable from "../../components/data-table";
import "./index.scss";

function Diagnostics(props) {
  const [browserInfo, setBrowserInfo] = useState();
  const [failedTests, setFailedTests] = useState();

  const handlePaste = (evt) => {
    setBrowserInfo(null);
    setFailedTests(null);
    const data = evt.clipboardData.getData("Text");
    if (!data || data.length === 0) {
      return;
    }
    try {
      let json = JSON.parse(data);
      setBrowserInfo(json.browserInfo);
      setFailedTests(json.connectivityFailures || []);
    } catch (err) {}
  };

  return (
    <div className="diagnostics">
      <Helmet title="Diagnostics" />
      <Header title="Diagnostics" />
      <Content>
        <div className="diagnostics__input">
          <textarea placeholder="Paste JSON" onPaste={handlePaste}></textarea>
        </div>
        <div className="diagnostics__results">
          {browserInfo ? (
            <DataTable
              title="Browser info"
              rows={Object.entries(browserInfo).map(([key, value]) => {
                return { title: key, data: value };
              })}
            ></DataTable>
          ) : null}
          {failedTests ? (
            <DataTable
              title={
                failedTests.length === 0
                  ? "Connectivity tests passed"
                  : "Failed tests"
              }
              rows={failedTests.map((test) => {
                return { title: test.name, data: test.error };
              })}
            ></DataTable>
          ) : null}
        </div>
      </Content>
    </div>
  );
}

export default Diagnostics;
