import React, { useEffect } from "react";
import Input from "./input";
import uuid from "node-uuid";

const TextBox = React.forwardRef((props, ref) => {
  const {disabled,
    autoFocus,
    onFocus,
    onBlur,
    onChange,
    onPaste,
    type,
    value,
    name,
    maxLength,
    placeholder,
    min,
    max,
    innerRef,
  } = props;

  const id = props.id ?? uuid.v4();

  useEffect(() => {
    if (autoFocus) {
      document.getElementById(id).focus();
    }
  } , [autoFocus, id]);

  const currentRef = innerRef || ref;
  const opts = {
    disabled,
    onFocus,
    onBlur,
    onChange,
    onPaste,
    type: type ? type : 'text',
    id: currentRef.current ? currentRef.current.id : undefined,
  };

  return (
    <input
      {...opts}
      className="input__input"
      ref={currentRef}
      value={value}
      name={name}
      placeholder={placeholder}
      maxLength={maxLength}
      min={min}
      max={max}
      onChange={(e) => onChange(e.target.value)}
    />
  );
});

export default Input(TextBox);