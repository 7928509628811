import React from "react";
import { Helmet } from "react-helmet"
import Header from "../../components/header";
import Content from "../../components/content";
import LibraryLookup from "../../components/library-lookup";
// import './index.scss';

function LibrariesPage(props) {
  return (
    <div>
      <Helmet title="Libraries" />
      <Header title="Libraries"></Header>
      <Content>
        <LibraryLookup />
      </Content>
    </div>
  );
}

export default LibrariesPage;
